import Vue from 'vue'
import VueRouter from 'vue-router'

// views
const viewLogin = () => import('@/views/login');
const viewInscription = () => import('@/views/inscription');
const viewLanding = () => import('@/views/landing');
const viewPrivate = () => import( '@/views/private');
const viewDossierDrop = () => import( '@/views/dossierDrop');
const viewEtudeHome = () => import( '@/views/etudeHome');
const viewReinscriptionHome = () => import( '@/views/reinscriptionHome');
const viewSuiviDemandes = () => import('@/components/suiviDemandes/index');
const viewSuiviEtude = () => import('@/components/etude/suiviEtude/index');
const viewSuiviReinscription = () => import('@/components/reinscription/suiviReinscription/index');
const viewDocumentsCenter = () => import('@/views/documentsCenter');
const viewDownloadDocuments = () => import('@/views/downloadDocuments');
const viewHome = () => import( '@/views/home');
const viewSoutenance = () => import( '@/views/soutenance/index');
const viewPwdInitialisation = () => import( '@/components/pwdInitialisation');

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'landing',
		component: viewLanding,
	},
	{
		path: '/login',
		name: 'login',
		component: viewLogin
	},
	/*{
		path: '/inscription',
		name: 'inscription',
		component: viewInscription
	},*/
	{
		path: '/resetpassword',
		name: 'pwdInitialisation',
		component: viewPwdInitialisation,
		/*beforeEnter: (to, from, next) => {
			console.log("navGard, pwdInitialisation")
			store.dispatch("check_token_validation", {id : to.params.id, token :to.params.token}).then(() => {
				next();
			})
		}*/
	},
	{
		path: '/private',
		name: 'private',
		component: viewPrivate,
		children: [
			{
				path: 'home',
				name: 'home',
				component: viewHome,
			},
			{
				path: 'dossier-upload',
				name: 'dossier-upload',
				component: viewDossierDrop,
			},
			{
				path: 'espace-reinscription',
				name: 'espace-reinscription',
				component: viewReinscriptionHome,
			},
			{
				path: 'espace-etude',
				name: 'espace-etude',
				component: viewEtudeHome,
			},
			{
				path: 'suivi-demande',
				name: 'suivi-demande',
				component: viewSuiviDemandes,
			},
			{
				path: 'suivi-reinscription',
				name: 'suivi-reinscription',
				component: viewSuiviReinscription,
			},
			{
				path: 'suivi-etude',
				name: 'suivi-etude',
				component: viewSuiviEtude,
			},
			{
				path: 'depo-document',
				name: 'depo-document',
				component: viewDocumentsCenter,
			},
			{
				path: 'telechargement-document',
				name: 'telechargement-document',
				component: viewDownloadDocuments,
			},
			{
				path: 'gestion-soutenance',
				name: 'gestion-soutenance',
				component: viewSoutenance,
			}
		],
		beforeEnter: (to, from, next) => {
			console.log("navGard, private")
			/*store.dispatch("initApp").then(() => {
				next();
			})*/
			next();
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router
